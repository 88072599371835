.footer {
  display: flex;
  justify-content: center;
}

.footer-content {
  align-items: center;
  padding: 0 20px;
}

/*.navbar-brand {*/
/*  font-size: 1.25rem;*/
/*}*/

/*.nav-link {*/
/*  font-size: 1.05rem;*/
/*}*/

.nav-item a:hover .fy-icon {
  display: none; /* Hide the default icon on hover */
}

.nav-item a:hover .fy-icon-hover {
  display: inline-block; /* Show the hover icon */
}

.nav-item a:hover .fy-text {
  display: none; /* Hide the default text on hover */
}

.nav-item a:hover .fy-text-hover {
  display: inline-block; /* Show the hover text */
}

.fy-icon-hover,
.fy-text-hover {
  display: none; /* Initially hide the hover icon and text */
}

/* end of custom things below this default react stuff from CRA */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
